<template>
	<div>
		<van-image width="100%" height="255px" fit="cover" :src="goods_info && goods_info.images.length > 0 ? goods_info.images[0] : ''" style="display: block;" />
		<van-cell-group class="from-group-item goods_top" style="display: flex;justify-content:space-between">
			<van-cell>
				<div style="font-size: 16px;" class="more-t">{{goods_info.moldbaby_name}}</div>
				<div style="margin-top: 15px;">
					<span style="font-size:20px;color: #ed1c24;font-weight: bold;">￥{{goods_info.real_price}}</span>
					<span style="font-size:12px;text-decoration:line-through;color: #aaa;">￥{{goods_info.retail_price}}</span>
				</div>
			</van-cell>
			<van-cell style="width: 30%;">
				<div>
					距结束
				</div>
				<div>
					<van-count-down :time="goods_info.enddate" format="HH 时 mm 分 ss 秒" style="color: #ed1c24"/>
				</div>
			</van-cell>
		</van-cell-group>
		<van-cell-group class="from-group-item" style="margin-top: 10px;">
			<van-cell style="line-height: 30px;">
				<div style="color:#26a2ff">抢购资格：报名即可参加</div>
				<div style="color: #26a2ff;">限购数量: {{goods_info.limitnumber == 0?'无限购':goods_info.limitnumber + '件'}} </div>
			</van-cell>
		</van-cell-group>
		<van-cell-group class="from-group-item" style="margin-top: 10px;margin-bottom: 70px;">
			<van-cell>
				<div style="color:grey">商品详情</div>
				<div v-html="goods_info.content" class="detail"></div>
			</van-cell>
		</van-cell-group>

		<div class="from-group-item group-detail-bottom">
			<div class="group-detail-bottom-item" @click="back()">
				<div><i class="iconfont icon-lvzhou_gengduo_xiangqing"></i></div>
				<div>活动详情</div>
			</div>
			<div class="group-detail-bottom-item" @click="order()">
				<div><i class="iconfont icon-huo1"></i></div>
				<div>爆款订单</div>
			</div>
			<div class="group-detail-bottom-button" :style="1 > Number(goods_info.inventory) || goods_info.inventory == 0 ? 'background-color: gray;':'background-color: #3487E2;'" @click="reserve()">
				<div>￥{{goods_info.deposit_price}}</div>
				<div>{{1 > Number(goods_info.inventory) || goods_info.inventory == 0 ? '已抢完':'立即预定'}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Field,
		Row,
		Col,
		Cell,
		CellGroup,
		Button,
		Toast,
		Stepper,
		Image,
		CountDown
	} from "vant";
	import {
		activity_detail,
		user_marketing_hot_goodsdetail, //爆款详情
	} from "@/request/api";

	export default {
		components: {
			[Field.name]: Field,
			[Row.name]: Row,
			[Col.name]: Col,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[Button.name]: Button,
			[Stepper.name]: Stepper,
			[Image.name]: Image,
			[CountDown.name]: CountDown
		},
		data() {
			return {
				goods_id: 0,
				goods_info: false,
				activity_id: '',
				is_enroll:0
			}
		},

		mounted: function() {
			this.goods_id = this.$route.query.id;
			this.activity_id = this.$route.query.activity_id
			this.initData();
			this.loadData()
		},
		methods: {
			loadData() {
				activity_detail({
					activity_id: this.activity_id,
					member_id: 0,
					superior: 0
				}).then(res => {
					this.is_enroll = res.data.is_enroll
				}).catch(err => {})

			},
			initData() {
				user_marketing_hot_goodsdetail({
					rid: this.goods_id,
					aid: this.activity_id
				}).then(res => {
					if (res.code == 1) {
						this.goods_info = res.data;
					} else {
						this.$dialog({
							title: '提示',
							message: res.msg
						}).then(g => {
							this.$router.go(-1);
						});
					}
				}).catch(error => {
					this.$dialog({
						title: '提示',
						message: "访问出错"
					}).then(g => {
						this.$router.go(-1);
					});
				});
			},
			reserve() {
				if (this.goods_info.inventory==0 || this.goods_info.inventory < 1) {
					this.$dialog({message: '商品已被抢完'})
					return false
				}
				if (!this.is_enroll > 0) {
					this.$dialog({
						message: '填写信息，确认购买'
					}).then(res => {
						this.$router.push({
							path: "/activity/enroll",
							query: {
								id: this.activity_id,
								back: 1,
								goods: this.goods_id
							}
						});
					}).catch(err => {})
					return false
				}
				this.$router.push({
					path: '/activity/marketing/hot/toOrder',
					query: {
						id: this.goods_info.id,
						activity_id: this.activity_id
					}
				})
			},
			order() {
				this.$router.push({
					path: '/activity/marketing/hot/order',
					query: {
						activity_id: this.goods_info.activity_id
					}
				})
			},
			back() {
				this.$router.go(-1)
			}
		}
	}
</script>

<style scoped lang="less">
	.group-detail-bottom {
		position: fixed;
		bottom: 0;
		left: 0;
		display: flex;
		flex-flow: row nowrap;
		background-color: white;
		width: 100%;
		overflow: hidden;
		text-align: center;
		height: 50px;
		align-items: center;

		.group-detail-bottom-item {
			display: inline-block;
			font-size: 10px;
			width: 25%;

			div {
				margin-top: 3px;
			}
		}

		.group-detail-bottom-button {
			display: flex;
			font-size: 13px;
			color: white;
			width: 50%;
			height: 100%;
			flex-flow: wrap;
			align-items: center;

			div {
				width: 100%;
			}
		}

	}

	.from-group-item{
		&::after{
			border: none;
		}
	}
	
	.detail{
		/deep/ img{
			width: 100% !important;
		}
	}
	
	.goods_top{
		border-radius: 15px 15px 0 0;
		overflow: hidden;
		margin-top: -10px;
	}
</style>
